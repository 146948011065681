/*
 * @catalogue: 设置弹窗
 * @Description: table表头、设置弹窗内容、
 * @Author: 黄红燕
 * @Date: 2021-01-18 14:24:52
 */
import React from 'react';
import { observable, action } from 'mobx';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import moment from 'moment';
import { circleStatus } from 'components/StateLess';
import { server_list, MouldStatus } from 'constants/status_constant'
import { Progress, Tag, Badge } from 'antd';
import $ from 'jquery';
import DeviceParamsSettingStore from './DeviceParamsSettingStore'
import Checkbox from 'antd/lib/checkbox/Checkbox';
// 质量管理-质检配置-质检计划
const cycleData = [
    { id: 0, name: '分钟', max: 60 },
    { id: 1, name: '小时', max: 24 },
    { id: 2, name: '天', max: 31 },
    { id: 3, name: '周', max: 5 },
    { id: 4, name: '月', max: 12 },
    { id: 5, name: '年', max: 1000 },
]
// 设备管理-设备点检/保养
const label_configs = {
    1: '点检',
    2: '保养',
    // 3: '故障'
}
const renderCell = (record, key) => {
    return record[key] || '-'
}
// 质检类型
const inspectType = [
    { name: '首检', id: 1 },
    { name: '自检', id: 2 },
    { name: '巡检', id: 3 },
    { name: '工序检', id: 4 },
    { name: '末检', id: 5 },
    { name: '全检',id: 11},
    { name: 'QC',id: 22},
    { name: '调试', id: 33},
]

// 工单进程
const curProcedureStep = [
    { name: '上线', id: 1 },
    { name: '总装', id: 2 },
    { name: '下线', id: 3 },
    { name: '调试', id: 4 },
    { name: '完成', id: 5 },
]
//工厂日历
const renderAttendence = (record, key) => {
    if (record[key] === 0) {
        return '上班'
    } else {
        return '休息'
    }
}
const renderCalendar = (record, key) => {
    if (record[key] === 1) {
        return '一级'
    } else if (record[key] === 2) {
        return '二级'
    } else if (record[key] === 3) {
        return '三级'
    } else {
        return ''
    }
}

const statusChange = (e, text, record, index) => {
    let obj = $('.enableStatus')[index]
    let abled = 'rgb(68, 35, 217)'
    let disabled = 'rgb(153, 153, 153)'
    DeviceParamsSettingStore.editEnableStatus({ id: record.id }).then(res => {
        if (res) {
            console.log($('.enableStatus')[index], $('.enableStatus')[index].style.color)
            obj.innerHTML = obj.innerHTML == '已启用' ? '已禁用' : '已启用'
            obj.style.color = obj.style.color == abled ? disabled : abled
        }
    })
}

class Store {
    @observable modalVisible = false; // 设置弹窗是否显示
    @observable current = []; // 当前页面表格列
    // 计划管理-生产计划
    @observable '/plan/task' = [
        {
            title: '生产计划号',
            dataIndex: 'planNo',
            key: 'planNo',
            width: 240,

        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode',
            width: 180,
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
            width: 180,
        },
        {
            title: '生产计划量',
            dataIndex: 'planCount',
            key: 'planCount',
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '交付日期',
            dataIndex: 'deadline',
            key: 'deadline',
            width: 180,
        },
        {
            title: '计划状态',
            dataIndex: 'status',
            key: 'status',
            width: 180,
            render: (value) => {
                return circleStatus(value, 'plan')
            }
        },
        {
            title: '已排程数量',
            dataIndex: 'num',
            key: 'num',
            width: 160,
        }
    ];
    // 计划管理-制造订单
    @observable '/plan/manufacture' = [
        {
            title: '生产计划号',
            dataIndex: 'planNo',
            key: 'planNo',
            width: 240,

        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode',
            width: 180,
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
            width: 180,
        },
        {
            title: '生产计划量',
            dataIndex: 'planCount',
            key: 'planCount',
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '交付日期',
            dataIndex: 'deadline',
            key: 'deadline',
            width: 180,
        },
        {
            title: '计划状态',
            dataIndex: 'status',
            key: 'status',
            width: 180,
            render: (value) => {
                return circleStatus(value, 'plan')
            }
        },
        {
            title: '已排程数量',
            dataIndex: 'num',
            key: 'num',
            width: 160,
        }
    ];
    // 生产管理-工单管理
    @observable '/production/workorder' = [
        {
            title: '工单编码',
            dataIndex: 'workNo',
            key: 'workNo',
            width: 160,
        },
        {
            title: '生产计划号',
            dataIndex: 'planNo',
            key: 'planNo',
            width: 210,
        },
        // {
        //     title: '设备名称',
        //     dataIndex: 'deviceName',
        //     key: 'deviceName',
        //     width: 120,
        // },
        // {
        //     title: '物料编码',
        //     dataIndex: 'materialCode',
        //     key: 'materialCode ',
        //     width: 120,
        // },
        // {
        //     title: '物料名称',
        //     dataIndex: 'materialName',
        //     key: 'materialName',
        //     width: 120,
        // },
        // {
        //     title: '工序',
        //     dataIndex: 'procedureName',
        //     key: 'procedureName',
        //     width: 120,
        // },
        // {
        //     title: '车间',
        //     dataIndex: 'workshopName',
        //     width: 120,
        // },
        // {
        //     title: '计划产量',
        //     dataIndex: 'planCount',
        //     key: 'planCount',
        //     width: 120,
        // },
        // {
        //     title: '合格数',
        //     dataIndex: 'successCount',
        //     key: 'successCount',
        //     width: 120,
        // },
        // {
        //     title: '不合格数',
        //     dataIndex: 'failCount',
        //     key: 'failCount',
        //     width: 120,
        // },
        // {
        //     title: '进度',
        //     dataIndex: 'percent',
        //     key: 'percent',
        //     width: 120,
        //     render: (value) => {
        //         return value && <Progress width={55} percent={(value.replace('%', '') || 0)}
        //             format={percent => <text style={{ color: value > 1 ? 'red' : undefined }}>{value}</text>}
        //             strokeColor={value > 1 ? 'red' : undefined} />
        //         // return <Progress width={55} percent={(value || 0) * 100}
        //         //     format={percent => <text style={{ color: value > 1 ? 'red' : undefined }}>{`${((value || 0) * 100).toFixed()}%`}</text>}
        //         //     strokeColor={value > 1 ? 'red' : undefined} />
        //     }
        // },
        {
            title: '工单状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (value) => {
                return value ? circleStatus(value, 'work') : '-'
            },
        },
        {
            title: '工单进程',
            dataIndex: 'curProcedureStep',
            key: 'curProcedureStep',
            width: 100,
            render: (value) => value === 1 ? <Tag color="success">上线</Tag>
                : value === 2 ? <Tag color="warning">总装</Tag>
                    : value === 3 ? <Tag color="warning">下线</Tag>
                        : value === 4 ? <Tag color="warning">调试</Tag>
                            : value === 5 ? <Tag color="default">完成</Tag>
                            : ''
        },
        {
            title: 'SN号',
            dataIndex: 'sn',
            key: 'sn',
            width: 140,
        },
        {
            title: '钣金号',
            dataIndex: 'sheetMetalCode',
            key: 'sheetMetalCode',
            width: 150,
        },
        {
            title: '主板号',
            dataIndex: 'pcbaCode',
            key: 'pcbaCode',
            width: 150,
        },
        {
            title: '开启时间',
            dataIndex: 'actualStartTime',
            key: 'actualStartTime',
            width: 160,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 160,
        },

        // {
        //     title: '计划完成时间',
        //     dataIndex: 'endTime',
        //     width: 175,
        // },
        {
            title: '实际完成时间',
            dataIndex: 'actualEndTime',
            width: 160,
        }
    ];
    // 生产管理-生产报工
    @observable '/production/report' = [
        {
            title: '工单编码',
            dataIndex: 'workNo',
            key: 'workNo',
            width: 230,
        },
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode',
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
        },
        {
            title: '计划产量',
            dataIndex: 'planCount',
            key: 'planCount',
        },
        {
            title: '完成产量',
            dataIndex: 'totalReportCount',
            key: 'totalReportCount',
        },
        {
            title: '进度',
            dataIndex: 'totalReportCount',
            key: 'totalReportCount',
            render: (value, record) => {
                return (`${((value / record.planCount) * 100).toFixed(2)}%`)
            }
        },
        {
            title: '工单状态',
            dataIndex: 'workStatus',
            key: 'workStatus',
            render: (value) => {
                return circleStatus(value, 'work')
            }
        },
        {
            title: '执行时间',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (value) => moment(value || new Date()).format('YYYY-MM-DD')
        },
    ];
    // 生产管理-产量统计
    @observable '/production/output' = [
        {
            title: '生产计划',
            dataIndex: 'planNo',
            key: 'planNo',
            width: 220,
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
            width: 160,
        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode ',
            width: 140,
        },
        {
            title: '物料规格',
            dataIndex: 'unit',
            key: 'unit',
            width: 140,
        },
        {
            title: '计划数量',
            dataIndex: 'workPlanCount',
            key: 'workPlanCount',
            width: 140,
        },
        {
            title: '完成数量',
            dataIndex: 'workSuccessCount',
            key: 'workSuccessCount',
            width: 140,
        },
        {
            title: '进度',
            dataIndex: 'workCountPercent',
            key: 'workCountPercent',
            width: 140,
            render: (value) => {
                return (
                    <Progress width={50} percent={(value || 0) * 100}
                        format={percent => <text style={{ color: 'gray' }}>{`${((value || 0) * 100).toFixed()}%`}</text>}
                    />
                )
            }
        },
        {
            title: '计划状态',
            dataIndex: 'status',
            key: 'status',
            width: 140,
            render: (value) => {
                return circleStatus(value, 'plan')
            }

        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
            width: 140,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
    ];
    // 生产管理-生产进度
    @observable '/production/progress' = [
        {
            title: '工单编号',
            dataIndex: 'workNo',
            key: 'workNo',
            width: 200,
        },
        {
            title: '计划数量',
            dataIndex: 'planCount',
            key: 'planCount',
            width: 200,
        },
        {
            title: '合格数量',
            dataIndex: 'successCount',
            key: 'successCount',
            width: 200,
        },
        {
            title: '进度',
            dataIndex: 'percent',
            key: 'percent',
            width: 340,
            render: (value) => {
                return value && <Progress strokeLinecap="square" percent={(value.replace('%', '') || 0)} size="small" />
                // return <Progress strokeLinecap="square" percent={`${((value || 0) * 100).toFixed(2)}`} size="small" />
            }
        }
    ];
    // 生产管理-计件报表
    @observable '/production/PieceworkSystem' = [
        { title: '员工', dataIndex: 'operator', key: 'operator', width: 150 },
        { title: '物料编码', dataIndex: 'materialCode', key: 'materialCode', width: 110 },
        { title: '物料名称', dataIndex: 'materialName', key: 'materialName', width: 110 },
        { title: '工序', dataIndex: 'procedureName', key: 'procedureName', width: 80 },
        { title: '设备名称', dataIndex: 'deviceName', key: 'deviceName', width: 110 },
        { title: '生产工单', dataIndex: 'workNo', key: 'aworkNo', width: 210 },
        { title: '报工时间', dataIndex: 'reportTime', key: 'reportTime', width: 200 },
        { title: '合格数（只）', dataIndex: 'successCount', key: 'successCount', width: 110 },
        { title: '不合格数（只）', dataIndex: 'failCount', key: 'failCount', width: 140 },
        {
            title: '合格率(%)', dataIndex: 'qualificationRate', key: 'qualificationRate', width: 160,
            render: (value) => {
                return <Progress width={55} percent={(value || 0)}
                    format={percent => <text style={{ color: value > 1 ? 'green' : undefined }}>{`${((value || 0)).toFixed()}%`}</text>}
                    strokeColor={value > 1 ? 'green' : undefined} />
            }
        },
        // { title: '工资(元)', dataIndex: 'salary', key: 'salary', width: 110 },

    ]
    // 生产管理-计件工资
    @observable '/production/PieceworkWage' = [
        { title: '物料编码', dataIndex: 'materialCode', key: 'materialCode' },
        { title: '物料名称', dataIndex: 'materialName', key: 'materialName' },
        { title: '工序名称', dataIndex: 'procedureName', key: 'procedureName' },
        { title: '计件单价', dataIndex: 'price', key: 'price' },
        { title: '生效时间', dataIndex: 'effectTime', key: 'effectTime' },
    ]
    // 生产管理-报工记录
    @observable '/production/reportRecord' = [
        { title: '报工人员', dataIndex: 'operator' },
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编码', dataIndex: 'deviceCode' },
        { title: '物料名称', dataIndex: 'materialName' },
        { title: '物料编码', dataIndex: 'materialCode' },
        { title: '生产计划', dataIndex: 'planNo', width: 150 },
        { title: '生产工单', dataIndex: 'workNo', width: 180 },
        { title: '流转卡号', dataIndex: 'circleCardCode', width: 225 },
        { title: '报工状态', dataIndex: 'status' },
        { title: '工序', dataIndex: 'procedureName' },
        { title: '报工时间', dataIndex: 'reportTime', width: 180 },
        { title: '合格数', dataIndex: 'successCount' },
        { title: '不合格数', dataIndex: 'failCount' },
        { title: '工废数量', dataIndex: 'industryCount' },
        { title: '料废数量', dataIndex: 'scrapCount' },
        { title: '合格率', dataIndex: 'qualificationRate', render: value => value + '%' }
    ]
    // 质量管理-质检配置-质检项
    @observable '/inspect/define?tab=1' = [
        {
            title: '工序名称',
            dataIndex: 'procedureName',
        },
        {
            title: '质检项名称',
            dataIndex: 'content',
        },
        {
            title: '质检项类型',
            dataIndex: 'contentType',
            key: 'contentType',
            render: value => value ? '输入型' : '是否型',
        },
        {
            title: '创建人',
            dataIndex: 'createUserName',
            key: 'createUserName'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: '修改人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName'
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime'
        }
    ];
    // 质量管理-质检配置-质检方案
    @observable '/inspect/define?tab=2' = [
        {
            title: '物料名称',
            dataIndex: 'materialName',
        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
        },
        {
            title: '图号',
            dataIndex: 'materialDrawingNumber',
        },
        {
            title: '工序',
            dataIndex: 'procedureName',
        },
        {
            title: '质检方案',
            dataIndex: 'pathName',
        },
        {
            title: '质检类型',
            dataIndex: 'inspectType',
            render: value => value && inspectType.find(item => item.id == value).name
        },
        {
            title: '样本配比',
            dataIndex: 'inspectNumType',
            render: value => value ? '比例' : '固定'
        },
        {
            title: '样本参数',
            dataIndex: 'inspectNum',
        },
        {
            title: '状态',
            dataIndex: 'inspectStatus',
            render: value => value ? '禁用' : '启用'
        },
        {
            title: '质检项数量',
            dataIndex: 'inspectCount',
        },
        {
            title: '备注',
            dataIndex: 'remarks',
        },
        {
            title: '创建人',
            dataIndex: 'createUserName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
        },
        {
            title: '编辑人',
            dataIndex: 'modifyUserName',
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
        },
    ];
    // 质量管理-质检配置-质检计划
    @observable '/inspect/define?tab=3' = [
        {
            title: '计划名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '绑定物料',
            dataIndex: 'materialName',
            key: 'materialName',
            render: (value, record) => value ? record.materialCode + '_' + value : '-'
        },
        {
            title: '绑定工序',
            dataIndex: 'procedureName',
            key: 'procedureName'
        },
        {
            title: '方案名称',
            dataIndex: 'pathName',
            key: 'pathName'
        },
        {
            title: '计划等待时间',
            dataIndex: 'waitTime',
            key: 'waitTime',
            render: (value) => {
                return value + '分钟'
            }
        },
        {
            title: '计划执行周期',
            dataIndex: 'cycleCount',
            key: 'cycleCount',
            render: (value, record) => value ? record.cycleCount + (cycleData.filter(item => item.id === record.cycle)[0].name) : '-'
        },
    ]
    // 质量管理-质检配置-质检结果
    @observable '/inspect/define?tab=4' = [
        {
            title: '物料名称',
            dataIndex: 'materialName',
        },
        {
            title: '物料编码',
            dataIndex: 'materialCode',
        },
        {
            title: '工序',
            dataIndex: 'procedureName',
        },
        {
            title: '质检方案',
            dataIndex: 'pathName',
        },
        {
            title: '生产计划号',
            dataIndex: 'planNo',
            width: 220
        },
        {
            title: '工单编号',
            dataIndex: 'workNo',
            width: 220
        },
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 180
        },
        {
            title: '质检类型',
            dataIndex: 'type',
            width: 150,
            render: value => value && inspectType.find(item => item.id == value).name
        },
        {
            title: '质检结果',
            dataIndex: 'result',
            key: 'result',
            width: 140,
            render: (value) => value === 2 ? <Tag icon={<CloseCircleOutlined />} color="error">不合格</Tag>
                : value == 1 ? <Tag icon={<CheckCircleOutlined />} color="success">合格</Tag>
                    : value === 3 ? <Tag icon={<ExclamationCircleOutlined />} color="warning">已关闭</Tag>
                        : value === 4 ? <Tag icon={<ExclamationCircleOutlined />} color="warning">已过期</Tag>
                            : <Tag icon={<ExclamationCircleOutlined />} color="default">未质检</Tag>
        },
        {
            title: '执行人',
            dataIndex: 'execureUser',
            width: 120,
        },
        {
            title: '计划质检时间',
            dataIndex: 'createTime',
            width: 180
        },
        {
            title: '实际质检时间',
            dataIndex: 'executeTime',
            key: 'executeTime',
            width: 180,
        }
    ]
    // 设备管理-设备点检-点检项
    @observable '/device/Inspection?tab=1' = this.setServerColumns({ type: 1 })
    @action setServerColumns({ handleEdit, handleDelete, type }) {
        return type === 1 ? [
            {
                title: label_configs[type] + '类型',
                dataIndex: 'contentType',
                key: 'contentType',
                render: value => {
                    let current = server_list.filter(item => item.id === value)[0]
                    return current ? current.name : '-'
                }
            },
            {
                title: label_configs[type] + '内容',
                dataIndex: 'content',
                key: 'content'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater'
            }
        ] : [
            {
                title: label_configs[type] + '类型',
                dataIndex: 'contentType',
                key: 'contentType',
                render: value => {
                    let current = server_list.filter(item => item.id === value)[0]
                    return current ? current.name : '-'
                }
            },
            {
                title: label_configs[type] + '内容',
                dataIndex: 'content',
                key: 'content'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater'
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime'
            },
            {
                title: '修改人',
                dataIndex: 'modifyName',
                key: 'modifyName'
            }
        ]
    }
    // 设备管理-设备点检-点检方案
    @observable '/device/Inspection?tab=2' = this.setSchemaColumns({ type: 1 })
    @action setSchemaColumns({ handleEdit, handleDelete, type }) {
        return type === 1 ? [
            {
                title: '方案名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: label_configs[type] + '项',
                dataIndex: 'configsIds',
                key: 'configsIds',
                render: value => value ? value.split(',').length : '-'
            },
            {
                title: '备注信息',
                dataIndex: 'description',
                key: 'description', render: (value) => value || '-'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime', render: (value) => value || '-'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater', render: (value) => value || '-'
            }
        ] : [
            {
                title: '方案名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: label_configs[type] + '项',
                dataIndex: 'configsIds',
                key: 'configsIds',
                render: value => value ? value.split(',').length : '-'
            },
            {
                title: '保养级别',
                dataIndex: 'grade',
                key: 'grade',
                render: (value) => (
                    value === 1 ? '一级保养' : value === 2 ? '二级保养' : value === 3 ? '三级保养' : ''
                )
            },
            {
                title: '备注信息',
                dataIndex: 'description',
                key: 'description', render: (value) => value || '-'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime', render: (value) => value || '-'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater', render: (value) => value || '-'
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime'
            },
            {
                title: '修改人',
                dataIndex: 'modifyName',
                key: 'modifyName'
            }
        ];
    }
    // 设备管理-设备点检-点检计划
    @observable '/device/Inspection?tab=5' = this.setPlanColumns({ type: 1 })
    @action setPlanColumns({ handleDelete, handleEdit, handleToDetail, handleRecover, type }) {
        return type === 1 ? [
            {
                title: '计划名称',
                dataIndex: 'planName',
                key: 'planName'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                key: 'deviceName'
            },
            {
                title: '方案名称',
                dataIndex: 'pathName',
                key: 'pathName'
            },
            {
                title: '计划执行时间',
                dataIndex: 'excuteTime',
                key: 'excuteTime'
            },
            {
                title: '计划等待时间',
                dataIndex: 'waitTime',
                key: 'waitTime',
                render: (value) => {
                    return value + '分钟'
                }
            },
            {
                title: '计划执行周期',
                dataIndex: 'cycleCount',
                key: 'cycleCount',
                render: (value, record) => value ? `${record.cycleCount}${record.cycleDesc}` : '-'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime'
            }
        ] : [
            {
                title: '计划名称',
                dataIndex: 'planName',
                key: 'planName'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                key: 'deviceName'
            },
            {
                title: '保养级别',
                dataIndex: 'pathGrade',
                key: 'pathGrade',
                render: (value) => (
                    value === 1 ? '一级保养' : value === 2 ? '二级保养' : value === 3 ? '三级保养' : ''
                )
            },
            {
                title: '方案名称',
                dataIndex: 'pathName',
                key: 'pathName'
            },
            {
                title: '计划执行时间',
                dataIndex: 'excuteTime',
                key: 'excuteTime'
            },
            {
                title: '计划等待时间',
                dataIndex: 'waitTime',
                key: 'waitTime',
                render: (value) => {
                    return value + '分钟'
                }
            },
            {
                title: '计划执行周期',
                dataIndex: 'cycleCount',
                key: 'cycleCount',
                render: (value, record) => value ? `${record.cycleCount}${record.cycleDesc}` : '-'
            },
            {
                title: '创建人',
                dataIndex: 'creater',
                key: 'creater'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime'
            },
            {
                title: '修改人',
                dataIndex: 'modifyName',
                key: 'modifyName'
            }
        ]
    }
    // 设备管理-设备点检-点检结果
    @observable '/device/Inspection?tab=4' = this.setQueryColumns({ type: 1 })
    @action setQueryColumns({ type, toDetail }) {
        return type === 1 ? [
            {
                title: '车间',
                dataIndex: 'workshopName',
            },
            {
                title: '设备类型',
                dataIndex: 'deviceTypeName',
                key: 'deviceTypeName'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                key: 'deviceName'
            },
            {
                title: label_configs[type] + '计划',
                dataIndex: 'planName',
                key: 'planName'
            },
            {
                title: label_configs[type] + '方案',
                dataIndex: 'pathName',
                key: 'pathName'
            },
            {
                title: label_configs[type] + '结果',
                dataIndex: 'resultDesc',
                key: 'resultDesc',
                render: (value, record) => record.result === 2 ? <Tag icon={<CheckCircleOutlined />} color="success">{value}</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">{value}</Tag>
            },
            {
                title: '计划' + label_configs[type] + '时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '更新' + label_configs[type] + '时间',
                dataIndex: 'executeTime',
                key: 'executeTime'
            },
            {
                title: '操作人',
                dataIndex: 'executeUser',
                key: 'executeUser'
            }
        ] : [
            {
                title: '车间',
                dataIndex: 'workshopName',
            },
            {
                title: '保养级别',
                dataIndex: 'pathGrade',
                key: 'pathGrade',
                render: (value) => (
                    value === 1 ? '一级保养' : value === 2 ? '二级保养' : value === 3 ? '三级保养' : ''
                )
            },
            {
                title: '设备类型',
                dataIndex: 'deviceTypeName',
                key: 'deviceTypeName'
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                key: 'deviceName'
            },
            {
                title: label_configs[type] + '计划',
                dataIndex: 'planName',
                key: 'planName'
            },
            {
                title: label_configs[type] + '方案',
                dataIndex: 'pathName',
                key: 'pathName'
            },
            {
                title: label_configs[type] + '结果',
                dataIndex: 'resultDesc',
                key: 'resultDesc',
                render: (value, record) => record.result === 2 ? <Tag icon={<CheckCircleOutlined />} color="success">{value}</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">{value}</Tag>
            },
            {
                title: '计划' + label_configs[type] + '时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '更新' + label_configs[type] + '时间',
                dataIndex: 'executeTime',
                key: 'executeTime'
            },
            {
                title: '操作人',
                dataIndex: 'executeUser',
                key: 'executeUser'
            }
        ]
    }
    // 设备管理-设备保养-保养项
    @observable '/device/Upkeep?tab=1' = this.setServerColumns({ type: 2 })
    // 设备管理-设备保养-保养方案
    @observable '/device/Upkeep?tab=2' = this.setSchemaColumns({ type: 2 })
    // 设备管理-设备保养-保养计划
    @observable '/device/Upkeep?tab=5' = this.setPlanColumns({ type: 2 });
    // 设备管理-设备保养-保养结果
    @observable '/device/Upkeep?tab=4' = this.setQueryColumns({ type: 2 })
    // 图文管理-设备文档
    @observable '/graphic/view?type=101' = this.setPicColumns({ typeId: 101 })
    @action setPicColumns({ handleDelete, handleEdit, typeId, handleCheck }) {
        if (typeId === 102 || typeId === 103) {
            return [
                { title: '绑定物料', dataIndex: 'name', key: 'name', },
                { title: '物料编码', dataIndex: 'code', key: 'code' },
                { title: '工序', dataIndex: 'secondRelationName', key: 'secondRelationName' },
                { title: '文件名', dataIndex: 'fileName', key: 'fileName' }
            ]
        }
        else if (typeId === 101 || typeId === 104) {
            return [
                {
                    title: typeId === 101 ? '绑定设备' : typeId === 104 ? '绑定工具' : '',
                    dataIndex: 'name', key: 'materialCode',
                },
                { title: '文件名', dataIndex: 'fileName', key: 'fileName' }
            ]
        }
        else if (typeId == 105) {
            return [{ title: '文件名', dataIndex: 'fileName', key: 'fileName' }]
        }
    }
    // 设备管理-设备维修-故障类型
    @observable '/device/maintain?tab=1' = [
        {
            title: '故障类型名称',
            dataIndex: 'faultName',
            key: 'faultName',
            width: 240,

        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 180,
        },
        {
            title: '创建人',
            dataIndex: 'createName',
            key: 'createName',
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '修改人',
            dataIndex: 'modifyName',
            key: 'modifyName',
            width: 180,
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 180,
        },
    ];
    // 设备管理-设备维修-故障等级
    @observable '/device/maintain?tab=2' = [
        {
            title: '故障等级名称',
            dataIndex: 'faultName',
            key: 'faultName',
            width: 240,

        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 180,
        },
        {
            title: '创建人',
            dataIndex: 'createName',
            key: 'createName',
            width: 180,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '修改人',
            dataIndex: 'modifyName',
            key: 'modifyName',
            width: 180,
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 180,
        },
    ];
    //设备管理-设备参数
    @observable '/device/params?tab=2' = [
        {
            title: '设备编码',
            dataIndex: 'deviceCode',
            key: 'deviceCode',
        },
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: '车间',
            dataIndex: 'workshop',
            key: 'workshop',
        },
        {
            title: '设备类型',
            dataIndex: 'deviceType',
            key: 'deviceType',
        },
        {
            title: '设备参数',
            dataIndex: 'collectContent',
            key: 'collectContent',
        },
        {
            title: '状态',
            dataIndex: 'enableStatus',
            key: 'enableStatus',
            render: (text, record, index) => (
                // text === 0 ? '已禁用' : '已启用'
                <span
                    onClick={(e) => statusChange(e, text, record, index)}
                    className="enableStatus"
                    style={{ cursor: "pointer", color: text === 0 ? '#999' : '#4423d9' }}
                >
                    {text === 0 ? '已禁用' : '已启用'}
                </span>
            )
        },
        {
            title: 'product_key',
            dataIndex: 'productKey',
            key: 'productKey',
        },
        {
            title: 'device_id',
            dataIndex: 'devId',
            key: 'devId',
        },
        {
            title: '字段名',
            dataIndex: 'collectField',
            key: 'collectField',
        },
        {
            title: '上限',
            dataIndex: 'upperLimit',
            key: 'upperLimit',
        },
        {
            title: '下限',
            dataIndex: 'lowerLimit',
            key: 'lowerLimit',
        },
        {
            title: '标准值',
            dataIndex: 'standardValue',
            key: 'standardValue',
        },
        {
            title: '以曲线形式展示',
            dataIndex: 'curveDisplay',
            key: 'curveDisplay',
            render: (text, record) => (
                text === 0 ? '否' : '是'
            )
        },
        {
            title: '创建人',
            dataIndex: 'createName',
            key: 'createName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
    ]
    // 图文管理-工艺文档
    @observable '/graphic/view?type=102' = this.setPicColumns({ typeId: 102 })
    // 图文管理-质量文档
    @observable '/graphic/view?type=103' = this.setPicColumns({ typeId: 103 })
    // 图文管理-其他文档
    @observable '/graphic/view?type=105' = this.setPicColumns({ typeId: 105 })
    // 工具管理-工具定义-模具定义
    @observable '/tools/define?tab=1' = this.setPlantColumns();
    @action setPlantColumns() {
        return [
            {
                title: '编号',
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '仓库',
                dataIndex: 'warehouseName',
                key: 'warehouseName'
            },
            {
                title: '库位',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: '出库时间',
                dataIndex: 'preTime',
                key: 'preTime', render: value => (value || 0) + '分钟'
            },
            {
                title: '换模时间',
                dataIndex: 'waitTime',
                key: 'waitTime', render: value => (value || 0) + '分钟'
            },
            {
                title: '出模时间',
                dataIndex: 'workTime',
                key: 'workTime', render: value => (value || 0) + '秒'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: text => (
                    <Tag color={
                        text === 1 ? 'green' : text === 2 ? 'cyan' : text === 3 ? 'red' :
                            text === 4 ? 'blue' : 'orange'
                    }>{MouldStatus[text]}</Tag>
                )
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: value => moment(value).format('YYYY-MM-DD')
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: value => moment(value).format('YYYY-MM-DD')
            }
        ];
    };
    // 工具管理-工具定义-工装定义 
    @observable '/tools/define?tab=2' = this.setPlantColumns();
    // 工具管理-工具定义-刀具定义 
    @observable '/tools/define?tab=4' = [
        { title: '刀具名称', dataIndex: 'name' },
        { title: '刀具编号', dataIndex: 'code' },
        { title: '刀具类型', dataIndex: 'type' },
        {
            title: '刀具状态', dataIndex: 'status',
            render: text => (
                <Tag color={
                    text === 1 ? 'green' : text === 2 ? 'cyan' : text === 3 ? 'red' :
                        text === 4 ? 'blue' : 'orange'
                }>{MouldStatus[text]}</Tag>
            )
        },
        { title: '创建时间', dataIndex: 'createTime' },
        { title: '创建人', dataIndex: 'creater' },
        { title: '更新时间', dataIndex: 'modifyTime' },
        { title: '更新人', dataIndex: 'modifyUser' }
    ];
    // 工具管理-工具寿命管理-模具
    @observable '/tools/life?tab=1' = this.setLifeColumns();
    @action setLifeColumns() {
        return [
            {
                title: '编号',
                dataIndex: 'mouldCode',
                key: 'mouldCode'
            },
            {
                title: '名称',
                dataIndex: 'mouldName',
                key: 'mouldName'
            },
            {
                title: '额定寿命',
                dataIndex: 'defaultLife',
                key: 'defaultLife'
            },
            {
                title: '告警寿命',
                dataIndex: 'alarmLife',
                key: 'alarmLife',
            },
            {
                title: '总寿命',
                dataIndex: 'totalLife',
                key: 'totalLife',
            },
            {
                title: '剩余寿命',
                dataIndex: 'rest',
                key: 'rest',
            }
        ];
    }
    // 工具管理-工具寿命管理-工装
    @observable '/tools/life?tab=2' = this.setLifeColumns();
    // 工具管理-工具寿命管理-刀具
    @observable '/tools/life?tab=4' = [
        { title: '刀具名称', dataIndex: 'mouldName' },
        { title: '刀具编号', dataIndex: 'mouldCode' },
        { title: '刀具类型', dataIndex: 'mouldType' },
        {
            title: '刀具状态', dataIndex: 'status', render: text => (
                <Tag color={
                    text == 1 ? 'green' : text == 2 ? 'cyan' : text == 3 ? 'red' :
                        text == 4 ? 'blue' : 'orange'
                }>{MouldStatus[text]}</Tag>
            )
        },
        { title: '已使用次数', dataIndex: 'useLife' }
    ]
    // 工具管理-工具寿命管理-刀具-详情
    @observable '/tools/life/detail' = [
        { title: '使用刀具的设备名称', dataIndex: 'deviceName' },
        { title: '使用刀具的设备代码', dataIndex: 'deviceCode' },
        { title: '流转卡单号', dataIndex: 'circleCardCode' },
        { title: '加工产品名称', dataIndex: 'materialName' },
        { title: '加工产品代码', dataIndex: 'materialCode' },
        { title: '单个产品的加工孔数', dataIndex: 'holeCount' },
        { title: '报工时间', dataIndex: 'reportTime' },
        { title: '绑定刀具时间', dataIndex: 'bindTime' },
        { title: '解绑刀具时间', dataIndex: 'unBindTime' },
        { title: '实际报工数', dataIndex: 'actReportCount' },
        { title: '实际打孔数', dataIndex: 'actHoleCount' },
        { title: '累计打孔数', dataIndex: 'totalCount' },
    ]
    // 异常响应-异常定义
    @observable '/abnormal/define' = [
        {
            title: '异常类型',
            dataIndex: 'abnormalTypeName',
            key: 'abnormalTypeName',
        },
        {
            title: '异常名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '分类层级',
            dataIndex: 'isLayeredReport',
            key: 'isLayeredReport',
            render: (text, record) => (
                <span>
                    {text ? (record.thirdLevelHandle ? '三级' : (record.secondLevelHandle ? '二级' : '一级')) : '一级'}
                </span>
            )
        },
        {
            title: '上报等待时间',
            dataIndex: 'awaitReportTime',
            key: 'awaitReportTime',
            render: text => <span>{text}分钟</span>
        },
        {
            title: '创建人',
            dataIndex: 'createUser',
            key: 'createUser',
        },
        {
            title: '一级接收人',
            dataIndex: 'firstLevelHandle',
            key: 'firstLevelHandle',
        },
        {
            title: '二级接收人',
            dataIndex: 'secondLevelHandle',
            key: 'secondLevelHandle',
        },
        {
            title: '三级接收人',
            dataIndex: 'thirdLevelHandle',
            key: 'thirdLevelHandle',
        }
    ];
    // 异常定义-异常呼叫
    @observable '/abnormal/call' = [
        {
            title: '设备',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: '异常名称',
            dataIndex: 'abnormalName',
            key: 'abnormalName',
        },
        {
            title: '接收人',
            dataIndex: 'handlePerson',
            key: 'handlePerson',
        },
        {
            title: '呼叫人',
            dataIndex: 'callUser',
            key: 'callUser',
        },
        {
            title: '异常等级',
            dataIndex: 'abnormalLevel',
            key: 'abnormalLevel',
            render: value => value === 1 ? <Tag icon={<QuestionCircleOutlined />} color='processing'>一般</Tag> : value === 2 ? <Tag icon={<ExclamationCircleOutlined />} color='warning'>重要</Tag> : <Tag icon={<StopOutlined />} color='error'>紧急</Tag>
        },
        {
            title: '呼叫时间',
            dataIndex: 'callTime',
            key: 'callTime',
        },
        {
            title: '响应时间',
            dataIndex: 'responseTime',
            key: 'responseTime',
            render: value => value || '-'
        },
        {
            title: '处理完成时间',
            dataIndex: 'completionTime',
            key: 'completionTime',
            render: value => value || '-'
        }
    ];
    // 异常定义-结果查询
    @observable '/abnormal/query' = [
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: '异常名称',
            dataIndex: 'abnormalName',
            key: 'abnormalName',
        },
        {
            title: '车间',
            dataIndex: 'workshopName',
        },
        {
            title: '接收人',
            dataIndex: 'handlePerson',
            key: 'handlePerson',
        },
        {
            title: '处理人',
            dataIndex: 'callUser',
            key: 'callUser',
        },
        {
            title: '结果',
            dataIndex: 'abnormalResult',
            key: 'abnormalResult',
            render: (text) => (
                <div>
                    {text == 1 ? <Tag icon={<CheckCircleOutlined />} color="success">成功</Tag> : text == 2 ? <Tag icon={<CloseCircleOutlined />} color="error" >失败</Tag> : <Tag icon={<CloseCircleOutlined />} color="default"  >未处理</Tag>}
                </div>
            )
        },
        {
            title: '异常等级',
            dataIndex: 'abnormalLevel',
            key: 'abnormalLevel',
            render: text => <div>
                {text == 1 ? <Tag icon={<QuestionCircleOutlined />} color='processing' >一般</Tag> : text == 2 ? <Tag icon={<ExclamationCircleOutlined />} color='warning' >重要</Tag> : text === 3 ? <Tag icon={<StopOutlined />} color='error' >紧急</Tag> : <Tag icon={<CheckCircleOutlined />} color='success' >正常</Tag>}
            </div>
        },
        {
            title: '呼叫时间',
            dataIndex: 'callTime',
            key: 'callTime',
            width: 180
        },
        {
            title: '响应时间',
            dataIndex: 'responseTime',
            key: 'responseTime',
            width: 180
        },
        {
            title: '处理完成时间',
            dataIndex: 'completionTime',
            key: 'completionTime',
            width: 180
        },
    ];
    // 知识图谱-工厂建模-工厂定义
    @observable '/basic/factoryuml?tab=1' = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: 240
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            width: 290,
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: text => (
                <div>
                    {!!text ? (
                        <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag>
                    ) : (
                        <Tag icon={<CloseCircleOutlined />} color='error'  >禁用</Tag>
                    )}
                </div>
            )
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '创建人',
            dataIndex: 'createUser',
            key: 'createUser'
        },
        {
            title: '更新时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 180,
            render: value => value || '-'
        },
        {
            title: '更新人',
            dataIndex: 'modifyUser',
            key: 'modifyUser',
            render: value => value || '-'
        }
    ];
    // 知识图谱-工厂建模-车间定义
    @observable '/basic/factoryuml?tab=2' = [
        {
            title: '编码',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            key: 'deptName'
        },
        {
            title: '负责人',
            dataIndex: 'management',
            key: 'management'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: text => (
                <div>
                    {text == 1 ? (
                        <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag>
                    ) : (
                        <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>
                    )}
                </div>
            )
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '创建人',
            dataIndex: 'createUser',
            key: 'createUser'
        },
        {
            title: '更新时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 180,
        },
        {
            title: '更新人',
            dataIndex: 'modifyUser',
            key: 'modifyUser'
        }
    ]
    //知识图谱-工厂日历-班次信息
    @observable '/basic/factorycalendar?tab=1' = [
        {
            title: '班次名称',
            dataIndex: 'attendenceName',
            key: 'attendenceName'
        },
        {
            title: '班次类型',
            dataIndex: 'attendenceKind',
            key: 'attendenceKind',
            render: (text, record) => renderAttendence(record, 'attendenceKind')
        },
        {
            title: '持续时间',
            dataIndex: 'attendenceTime',
            key: 'attendenceTime'
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description'
        }
    ]
    //知识图谱-工厂日历-日历信息
    @observable '/basic/factorycalendar?tab=2' = [
        {
            title: '设备/资源',
            dataIndex: 'deviceName',
            key: 'deviceName',
            render: (text, record) => renderCell(record, 'deviceName')
        },
        {
            title: '日期/星期',
            dataIndex: 'dateTime',
            key: 'dateTime',
            render: (text, record) => renderCell(record, 'dateTime')
        },
        {
            title: '班次名称',
            dataIndex: 'attendenceName',
            key: 'attendenceName',
            render: (text, record) => renderCell(record, 'attendenceName')
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            key: 'priority',
            render: (text, record) => renderCalendar(record, 'priority')
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description', render: (text, record) => renderCell(record, 'description')
        }
    ]
    // 知识图谱-设备定义-设备类型
    @observable '/basic/define?tab=1' = [
        {
            title: '设备类型名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '设备类型状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div>
                    {text ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>}
                </div>
            )
        }
    ];
    // 知识图谱-设备类型-设备组列表
    @observable '/basic/define?tab=3' = [
        {
            title: '设备组编码',
            dataIndex: 'groupCode',
            key: 'groupCode',
        },
        {
            title: '设备组名称',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: '设备组状态',
            dataIndex: 'groupStatus',
            key: 'groupStatus',
            render: (text) => (
                <div>
                    {text ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>}
                </div>
            )
        },
        {
            title: '设备类型',
            dataIndex: 'typeName',
            key: 'typeName', render: value => value || '-'
        },
        {
            title: '绑定工控机',
            dataIndex: 'ipcDoList',
            key: 'ipcDoList',
            render: text => <div>{text && this.getTag(text, 'id', 'name')}</div>
        },
        {
            title: '所属车间',
            dataIndex: 'workshopName',
            key: 'workshopName',
        }
    ];
    // 显示工控机tag
    @action getTag = (list, id, name) => {
        let data = []
        list.sort((a, b) => a.sort - b.sort)
        list.map((item) => {
            data.push(<Tag key={item[id]} >{item[name]}</Tag>)
        })
        return data
    }
    // 知识图谱-设备定义-设备列表
    @observable '/basic/define?tab=2' = [
        {
            title: '设备编号',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '设备名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '设备类型',
            dataIndex: 'deviceTypeName',
            key: 'deviceTypeName',
        },
        {
            title: '设备状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div>
                    {text ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>}
                </div>
            )
        },
        {
            title: '是否采集',
            dataIndex: 'needCollect',
            key: 'needCollect',
            render: value => <div>
                {value ? <Badge status='success' text='是'></Badge> : <Badge status='error' text='否'></Badge>}
            </div>
        },
        {
            title: '是否需要刀具',
            dataIndex: 'needKnifeTool',
            key: 'needKnifeTool',
            render: value => <div>
                {value ? <Badge status='success' text='是'></Badge> : <Badge status='error' text='否'></Badge>}
            </div>
        },
        {
            title: 'ProductKey',
            dataIndex: 'productKey',
            key: 'productKey',
        },
        {
            title: 'Device ID',
            dataIndex: 'devId',
            key: 'devId',
        },
        {
            title: '绑定工控机',
            dataIndex: 'ipcDoList',
            key: 'ipcDoList',
            render: text => <div>{text && this.getTag(text, 'id', 'name')}</div>
        },
        {
            title: '所属车间',
            dataIndex: 'workshopName',
            key: 'workshopName',
        },
        {
            title: '设备供应商',
            dataIndex: 'brandModel',
            key: 'brandModel',
        },
        {
            title: '启用时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description', render: value => value || '-'
        }
    ];
    // 知识图谱-物料定义
    @observable '/basic/material' = [
        {
            title: '物料编码',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => renderCell(record, 'code')
        },
        {
            title: '物料名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => renderCell(record, 'name')
        },
        {
            title: '物料图号',
            dataIndex: 'drawingNumber',
            key: 'drawingNumber',
            render: (text, record) => renderCell(record, 'drawingNumber')
        },
        {
            title: '属性',
            dataIndex: 'property',
            key: 'property',
            render: (text, record) => (
                <div>
                    {record.property === '1' ? '外购' : '自制'}
                </div>
            )
        },
        {
            title: '计量单位',
            dataIndex: 'unit',
            key: 'unit', render: (text, record) => renderCell(record, 'unit')
        },
        {
            title: '采购类型',
            dataIndex: 'buyType',
            key: 'buyType',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div>
                    {record.status ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error'>禁用</Tag>}
                </div>
            )
        }
    ];
    // 知识图谱-bom定义
    @observable '/basic/bom' = [
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode',
            render: (text, record) => renderCell(record, 'materialCode')
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
            render: (text, record) => renderCell(record, 'materialName')
        },
        {
            title: '属性',
            dataIndex: 'property',
            key: 'property',
            render: (text, record) => (
                <div>
                    {record.materialProperty == '1' ? '外购' : '自制'}
                </div>
            )
        },
        {
            title: '上级物料数量',
            dataIndex: 'targetNum',
            key: 'unit',
        },
        {
            title: '下级物料数量',
            dataIndex: 'sourceNum',
            key: 'sourceNum',
        }
    ];
    // 知识图谱-工序定义
    @observable '/basic/process' = [
        {
            title: '工序名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '设备类型',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: '工序状态',
            dataIndex: 'status',
            key: 'status',
            render: value => <div>
                {value ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag cicon={<CloseCircleOutlined />} color='error'  >禁用</Tag>}
            </div>
        },
        {
            title: '是否需要质检',
            dataIndex: 'inspect',
            key: 'inspect',
            render: value => <div>
                {value ? <Badge status='success' text='是'></Badge> : <Badge status='error' text='否'></Badge>}
            </div>
        }
    ];
    // 知识图谱-工艺路线
    @observable '/basic/techLine' = [
        {
            title: '物料编码',
            dataIndex: 'materialCode',
            key: 'materialCode',
        },
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
        },
        {
            title: '路线类型',
            dataIndex: 'techType',
            key: 'techType',
            render: (text, record) => (
                <div>
                    {text === 1 ? '主工艺' : '备用工艺'}
                </div>
            )
        },
        {
            title: '工序',
            dataIndex: 'procedureDoList',
            key: 'procedureDoList',
            render: text => <div>{text && this.getTag(text, 'procedureId', 'procedureName')}</div>
        }
    ];
    // 知识图谱-产能定义
    @observable '/basic/capacity' = [
        {
            title: '物料名称',
            dataIndex: 'materialName',
            key: 'materialName',
            render: (text, row, index) => {
                return {
                    children: <div>{text}</div>,
                    props: {
                        rowSpan: row.rowSpan ? row.rowSpan : 0,
                    },
                };
            },
        },
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            // render: renderContent,
        },
        {
            title: '理论产能（件/天）',
            dataIndex: 'output',
            key: 'output'
        }
    ];
    // 知识图谱-终端定义
    @observable '/basic/ipc' = [
        {
            title: '终端名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => renderCell(record, 'name')
        }, {
            title: '状态',
            dataIndex: 'status',
            key: 'status', render: (text) => text ? <Tag icon={<CheckCircleOutlined />} color='success'>启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => renderCell(record, 'address')
        },
        {
            title: 'IP',
            dataIndex: 'ipcIp',
            key: 'ipcIp', render: (text, record) => renderCell(record, 'ipcIp')
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime', render: (text, record) => renderCell(record, 'modifyTime')
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark', render: (text, record) => renderCell(record, 'remark')
        }
    ];
    // 知识图谱-制造bom
    @observable '/basic/bomManufacture' = [
        {
            title: '最终物料',
            dataIndex: 'boraCode',
            key: 'boraCode',
            width: 150,
        },
        {
            title: '工序编号',
            dataIndex: 'procNo',
            key: 'procNo',
        },
        {
            title: '工序代码',
            dataIndex: 'procCode',
            key: 'procCode',
        },
        {
            title: '指令种类',
            dataIndex: 'instructionType',
            key: 'instructionType',
        },
        {
            title: '指令代码',
            dataIndex: 'instructionCode',
            key: 'instructionCode',
        },
        {
            title: '物料/资源',
            dataIndex: 'itemOrResource',
            key: 'itemOrResource',
            width: 180,
        },
        {
            title: '前设置',
            dataIndex: 'task1Expr',
            key: 'task1Expr',
        },
        {
            title: '制造',
            dataIndex: 'task2Expr',
            key: 'task2Expr',
        },
        {
            title: '后设置',
            dataIndex: 'task3Expr',
            key: 'task3Expr',
        },
        {
            title: '转接方法',
            dataIndex: 'timeConstraintMethod',
            key: 'timeConstraintMethod',
        },
        {
            title: '移动时间MIN(小)',
            dataIndex: 'timeConstraintMin',
            key: 'timeConstraintMin',
            width: 160,
        },
        {
            title: '移动时间MIN(大)',
            dataIndex: 'timeConstraintMax',
            key: 'timeConstraintMax',
            width: 160,
        },
        {
            title: '版本号',
            dataIndex: 'version',
            key: 'version',
            render: text => text == 1 ? '主工艺' : '备用工艺'
        }
    ];
    // 知识图谱-仓库定义
    @observable '/basic/warehouse' = [
        {
            title: '仓库编码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '仓库名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '仓库类型',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => (
                <span>{text == 1 ? '原料仓' : text == 2 ? '线边仓' : '成品仓'}</span>
            )
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div>
                    {text ? <Tag icon={<CheckCircleOutlined />} color='success' >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>}
                </div>
            )
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '更新时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
        }
    ]
    // 报表管理-数据集
    @observable '/bigScreen/dataSet' = [
        { title: '客户名称', dataIndex: 'customerName' },
        { title: '发货数量', dataIndex: 'deliveryQuantity' },
        { title: '日期', dataIndex: 'date' },
        { title: '计划完成率', dataIndex: 'plannedCompletionRate' },
        { title: '设备名称', dataIndex: 'devcieName' },
        { title: '质量合格率', dataIndex: 'qualityQualificationRate' },
        { title: '销售目标', dataIndex: 'salesTarget' },
        { title: '销售总额', dataIndex: 'totalSalesVolume' },
        { title: '同比增长', dataIndex: 'sameRateIncrease' },
        { title: '有客户的省份', dataIndex: 'provincesWithCustomers' },
    ]
    // 系统管理-部门管理
    @observable '/system/department' = [
        {
            title: '部门名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '部门ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '所属工厂',
            dataIndex: 'factoryName',
            key: 'factoryName',
        }
    ];
    // 系统管理-角色管理
    @observable '/system/role' = [
        {
            title: '角色名',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        }
    ];
    // 系统管理-用户管理
    @observable '/system/user' = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'IC卡号',
            dataIndex: 'icCode',
            key: 'icCode',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div>
                    {text ? <Tag cicon={<CheckCircleOutlined />} color='success'  >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error'  >禁用</Tag>}
                </div>
            )
        }
    ];
    // 系统管理-租户管理
    @observable '/system/company' = [
        {
            title: '公司名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '用户名称',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div>
                    {text ? <Tag icon={<CheckCircleOutlined />} color='success'  >启用</Tag> : <Tag icon={<CloseCircleOutlined />} color='error' >禁用</Tag>}
                </div>
            )
        },
        {
            title: '公司id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '公司地址',
            dataIndex: 'address',
            key: 'address'
        },
    ];
    /**
     * @Description: 打开/关闭表格列设置弹窗
     */
    @action.bound modalChange() {
        this.modalVisible = !this.modalVisible;
    }

    /**
     * @Description: 清除数据
     */
    @action.bound clearData() {
        this.modalVisible = false;
        this.current = [];
    }
}

let SetStore = new Store();
export default SetStore;